<template>
  <div class="row">
    <div class="buy d-flex col-12 mb-4">
      <div class="mr-3">
        <div class="mb-2">
          State
        </div>
        <a-select
          style="width: 135px"
          :default-value="'action'"
        >
          <a-select-option value="action">
            Action
          </a-select-option>
          <a-select-option value="1">
            Another action
          </a-select-option>
          <a-select-option value="2">
            Something else here
          </a-select-option>
          <a-select-option value="3">
            Separated link
          </a-select-option>
        </a-select>
      </div>
      <div class="mr-3">
        <div class="mb-2">
          LATA
        </div>
        <a-select
          style="width: 135px"
          :default-value="'action'"
        >
          <a-select-option value="action">
            Action
          </a-select-option>
          <a-select-option value="1">
            Another action
          </a-select-option>
          <a-select-option value="2">
            Something else here
          </a-select-option>

          <a-select-option value="3">
            Separated link
          </a-select-option>
        </a-select>
      </div>
      <div class="mr-3">
        <div class="mb-2">
          Rate Center
        </div>
        <a-select
          style="width: 135px"
          :default-value="'action'"
        >
          <a-select-option value="action">
            Action
          </a-select-option>
          <a-select-option value="1">
            Another action
          </a-select-option>
          <a-select-option value="2">
            Something else here
          </a-select-option>

          <a-select-option value="3">
            Separated link
          </a-select-option>
        </a-select>
      </div>
      <div class="mr-3">
        <div class="mb-2">
          TN Mask
        </div>
        <a-form-item
          style="width: 135px"
          name="email"
        >
          <a-input placeholder="TN Mask" />
        </a-form-item>
      </div>
      <div class="mr-3">
        <div class="mb-2">
          TN WildCard
        </div>
        <a-form-item
          style="width: 135px"
          name="email"
        >
          <a-input placeholder="TN WildCard" />
        </a-form-item>
      </div>
      <div class="mr-3">
        <div class="mb-2">
          NXX
        </div>
        <a-select
          style="width: 135px"
          :default-value="'action'"
        >
          <a-select-option value="action">
            Action
          </a-select-option>
          <a-select-option value="1">
            Another action
          </a-select-option>
          <a-select-option value="2">
            Something else here
          </a-select-option>

          <a-select-option value="3">
            Separated link
          </a-select-option>
        </a-select>
      </div>
      <div class="mr-3">
        <div class="mb-2">
          NPA
        </div>
        <a-select
          style="width: 135px"
          :default-value="'action'"
        >
          <a-select-option value="action">
            Action
          </a-select-option>
          <a-select-option value="1">
            Another action
          </a-select-option>
          <a-select-option value="2">
            Something else here
          </a-select-option>

          <a-select-option value="3">
            Separated link
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="d-flex col-12">
      <div class="col-6">
        <h3>Search Result</h3>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">
                TN
              </th>
              <th scope="col">
                State
              </th>
              <th scope="col">
                OCN
              </th>
              <th scope="col">
                LATA
              </th>
              <th scope="col">
                Rate Center
              </th>
              <th scope="col">
                MRC
              </th>
              <th scope="col">
                Rate
              </th>
            </tr>
          </thead>
          <draggable
            v-model="list1"
            group="people"
            tag="tbody"
          >
            <template #item="{ element }">
              <tr :key="element.name">
                <td scope="row">
                  {{ element.tn }}
                </td>
                <td>{{ element.state }}</td>
                <td>{{ element.ocn }}</td>
                <td>{{ element.lata }}</td>
                <td>{{ element.rate_center }}</td>
                <td>{{ element.mrc }}</td>
                <td>{{ element.rate }}</td>
              </tr>
            </template>
          </draggable>
        </table>
      </div>

      <div class="col-6">
        <h3>Selected TNs</h3>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">
                TN
              </th>
              <th scope="col">
                MRC
              </th>
              <th scope="col">
                Rate
              </th>
            </tr>
          </thead>
          <draggable
            v-model="list2"
            group="people"
            tag="tbody"
          >
            <template #item="{ element }">
              <tr :key="element.name">
                <td scope="row">
                  {{ element.tn }}
                </td>
                <td>{{ element.mrc }}</td>
                <td>{{ element.rate }}</td>
              </tr>
            </template>
          </draggable>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
  },
  data() {
    return {
      list1: [
        { tn: 1, state: 2, ocn: 3, lata: 4, rate_center: 5, mrc: 6, rate: 7 },
        { tn: 42, state: 242, ocn: 243, lata: 442, rate_center: 425, mrc: 246, rate: 427 },
        { tn: 142, state: 2142, ocn: 2143, lata: 4412, rate_center: 4215, mrc: 2461, rate: 1427 },
      ],
      list2: [],
    }
  },
  methods: {
    add: function () {
      this.list.push({ name: 'Juan' })
    },
    replace: function () {
      this.list = [{ name: 'Edgard' }]
    },
    clone: function (el) {
      return {
        name: el.name + ' cloned',
      }
    },
    log: function (evt) {
      window.console.log(evt)
    },
  },
}
</script>

<style scoped>
>>> .ant-form-item-control {
  line-height: 100%;
}
</style>
